<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Vue from "vue";
import { Icon } from "@iconify/vue2";
import { attachmentTypeEnum } from "@/components/enums";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

Vue.use(CKEditor);

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Şirket Güncelleme",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Icon,
    vSelect,
  },
  data() {
    return {
      title: "Şirket Güncelleme",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Şirket Listesi",
          href: "/systemcompany",
        },
        {
          text: "Şirket Güncelleme",
          active: true,
        },
      ],
      values: {
        company_name: "",
        authorized_id: "",
        company_detail: "",
        credit: "",
      },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "indent",
          "outdent",
          "|",
          "blockQuote",
          "insertTable",
          "undo",
          "redo",
        ],
      },
      attachmentTypeEnum,
      customers: [],
      showNewCustomerForm: false,
      newCustomer: {
        customer_name: "",
        customer_phone: "",
        customer_email: "",
        customer_passport_number: "",
        customer_nationality: "",
        isActive: 1,
      },
      newCredit: {
        company_id: "",
        credit: "",
        credit_date: "",
      },
      newNotification: {
        company_id: "",
        total: "",
        file_url: "",
      },
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      totalRowsNotification: 0,
      currentPageNotification: 1,
      perPageNotification: 10,
      fields: [
        { key: "company", label: "Adı" },
        { key: "credit", label: "Kredi" },
        { key: "credit_date", label: "Son Yükleme Tarihi" },
      ],
      fieldsNotification: [
        { key: "company", label: "Adı" },
        { key: "total", label: "Toplam" },
        { key: "file_url", label: "Dosya" },
      ],
      showCreditModal: false,
      showNotificationModal: false,
      selectedCompany: null,
      companies: [],
      isBusy: false,
      isButton: false,
      uploadedFiles: [],
      isUploading: false
    };
  },
  async created() {
    await this.getCustomers();
    await this.getCompanies();
  },
  mounted() {
    const itemId = this.$route.params.id;
    const api_url = process.env.VUE_APP_BASEURL + `/system-company/${itemId}`;

    axios
      .get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.values = response.data?.data; // Get ile gelen verileri form alanlarına doldur
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    updateSystemCompany() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/system-company/${itemId}`;

      axios
        .put(api_url, this.values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$router.push("/systemcompany");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getCustomers() {
      const api_url = process.env.VUE_APP_BASEURL + "/customer/getAll";
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.customers = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async addNewCustomer() {
      try {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.token;
        const api_url = process.env.VUE_APP_BASEURL + "/customer";

        const response = await axios.post(api_url, this.newCustomer, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Yeni müşteriyi listeye ekle ve seç
        await this.getCustomers(); // Listeyi yenile
        this.values.authorized_id = response.data.data.id; // Yeni eklenen müşteriyi seç

        // Formu temizle ve gizle
        this.newCustomer = {
          customer_name: "",
          customer_phone: "",
          customer_email: "",
          customer_passport_number: "",
          customer_nationality: "",
          isActive: 1,
        };
        this.showNewCustomerForm = false;

        this.$toast.success("Yetkili başarıyla eklendi");
      } catch (error) {
        console.error("Müşteri eklenirken hata:", error);
        this.$toast.error("Yetkili eklenirken bir hata oluştu");
      }
    },
    getData(ctx, callback) {
      const params = "?page=" + ctx.currentPage;
      axios
        .get(
          process.env.VUE_APP_BASEURL +
            "/company-credits/" +
            this.$route.params.id +
            params,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            const items = response.data.data;
            this.totalRows = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            callback(items);
          }
        })
        .catch((error) => {
          callback([]);
          console.error("Veri yüklenirken bir hata oluştu:", error);
        });
      this.isBusy = false;
      return;
    },
    getDataNotification(ctx, callback) {
      const params = "?page=" + ctx.currentPage;
      axios
        .get(process.env.VUE_APP_BASEURL + "/payment-notifications/" + params, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const items = response.data.data;
            this.totalRowsNotification = response.data.meta.total;
            this.perPageNotification = response.data.meta.per_page;
            callback(items);
          }
        })
        .catch((error) => {
          callback([]);
          console.error("Veri yüklenirken bir hata oluştu:", error);
        });
    },
    onPageChangeNotification(newPage) {
      this.currentPageNotification = newPage;
    },
    onPageChange(newPage) {
      this.currentPage = newPage;
    },
    openCreditModal() {
      this.showCreditModal = true;
    },
    openNotificationModal() {
      this.showNotificationModal = true;
    },
    addCredit() {
      this.isButton = true;
      try {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.token;

        // Mevcut krediyi kontrol et, null ise 0 olarak başlat
        const newCredit = this.values.credit + this.newCredit.credit;

        // Tarih formatını ayarla (YYYY-MM-DD)
        const today = new Date();
        const formattedDate = today.toISOString().split("T")[0];

        const creditData = {
          company_id: this.$route.params.id, // URL'den şirket ID'sini al
          credit: this.newCredit.credit,
          credit_date: formattedDate,
        };

        axios
          .post(process.env.VUE_APP_BASEURL + "/company-credit", creditData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            this.showCreditModal = false;
            this.values.credit = newCredit;
            this.$refs.dataTable.refresh();
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.isButton = false;
          });
      } catch (error) {
        console.error("Kontor yüklenirken hata:", error);
      }
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      try {
        this.isUploading = true;
        const formData = new FormData();
        formData.append('file', file);

        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.token;

        const response = await axios.post(
          process.env.VUE_APP_BASEURL + '/upload',
          formData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            },
          }
        );

        // original_url'i file_url'e atıyoruz
        this.newNotification.file_url = response.data.data.original_url;
        this.$toast.success('Dosya başarıyla yüklendi');
      } catch (error) {
        console.error('Dosya yüklenirken hata:', error);
        this.$toast.error('Dosya yüklenirken bir hata oluştu');
        // Dosya input'unu temizle
        if (this.$refs.fileInput) {
          this.$refs.fileInput.value = '';
        }
      } finally {
        this.isUploading = false;
      }
    },
    async addNotification() {

      try {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.token;

        // Şirket ID'sini set et
        this.newNotification.company_id = this.$route.params.id;

        await axios.post(
          process.env.VUE_APP_BASEURL + '/payment-notification',
          this.newNotification,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Başarılı işlem sonrası
        this.newNotification = {
          company_id: '',
          total: '',
          file_url: '',
        };
        this.showNotificationModal = false;
        if (this.$refs.fileInput) {
          this.$refs.fileInput.value = '';
        }
        
        // Tabloyu yenile
        if (this.$refs.notificationTable) {
          this.$refs.notificationTable.refresh();
        }

        this.$toast.success('Ödeme bildirimi başarıyla eklendi');
      } catch (error) {
        console.error('Ödeme bildirimi eklenirken hata:', error);
        this.$toast.error('Ödeme bildirimi eklenirken bir hata oluştu');
      }
    },
    async getCompanies() {
      try {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.token;

        const response = await axios.get(
          process.env.VUE_APP_BASEURL + "/system-companies/getAll",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.companies = response.data.data;
      } catch (error) {
        console.error("Şirketler alınırken hata:", error);
        this.$toast.error("Şirketler yüklenirken bir hata oluştu");
      }
    },
    // Select için label ve value formatını belirleyen metod
    getCustomerLabel({ customer_name }) {
      return `${customer_name}`
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="updateSystemCompany">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="customer_name">Şirket Adı</label>
                    <input
                      v-model="values.company_name"
                      type="text"
                      class="form-control"
                      id="company_name"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="authorized_id">Yetkili Kişi</label>
                    <div class="d-flex gap-2">
                      <v-select
                        v-model="values.authorized_id"
                        :options="customers"
                        :get-option-label="getCustomerLabel"
                        :reduce="customer => customer.id"
                        class="flex-grow-1"
                        placeholder="Yetkili Kişi Seçiniz"
                        :searchable="true"
                        :filterable="true"
                      />
                      <button
                        type="button"
                        class="btn btn-primary mx-2"
                        @click="showNewCustomerForm = !showNewCustomerForm"
                        :disabled="isButton"
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="customer_name">Aktif Kontür</label>
                    <input
                      v-model="values.credit"
                      type="text"
                      class="form-control"
                      id="credit"
                      disabled
                    />
                  </div>
                </div>

                <!-- Yeni Müşteri Ekleme Formu -->
                <div
                  v-if="showNewCustomerForm"
                  class="border col-12 p-3 rounded mt-2"
                >
                  <h5>Yeni Yetkili Ekle</h5>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group mb-3">
                        <label for="customer_name">Müşteri Adı:</label>
                        <input
                          v-model="newCustomer.customer_name"
                          type="text"
                          class="form-control"
                          id="customer_name"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-3">
                        <label for="customer_phone">Telefon Numarası:</label>
                        <input
                          v-model="newCustomer.customer_phone"
                          type="text"
                          class="form-control"
                          id="customer_phone"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-3">
                        <label for="customer_email">Mail:</label>
                        <input
                          v-model="newCustomer.customer_email"
                          type="email"
                          class="form-control"
                          id="customer_email"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-3">
                        <label for="customer_passport_number"
                          >Pasaport Numarası:</label
                        >
                        <input
                          v-model="newCustomer.customer_passport_number"
                          type="text"
                          class="form-control"
                          id="customer_passport_number"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-3">
                        <label for="customer_nationality">Uyruk:</label>
                        <input
                          v-model="newCustomer.customer_nationality"
                          type="text"
                          class="form-control"
                          id="customer_nationality"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="addNewCustomer"
                      :disabled="isButton"
                    >
                      Ekle
                    </button>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-3">
                    <label for="company_detail">Şirket Detayı</label>
                    <ckeditor
                      v-model="values.company_detail"
                      :editor="editor"
                      :config="editorConfig"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="isButton"
                >
                  Güncelle
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div
                class="col-12 d-flex justify-content-between align-items-center mb-3"
              >
                <h4 class="card-title m-0">Kontür Listesi</h4>
                <button
                  @click="openCreditModal"
                  class="btn btn-primary"
                  :disabled="isButton"
                >
                  <Icon icon="lucide:plus-circle" class="mr-1" /> Ekle
                </button>
              </div>
            </div>

            <!-- Kredi Yükleme Modal -->
            <div
              class="modal"
              tabindex="-1"
              :class="{ 'd-block': showCreditModal }"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Kontor Yükleme</h5>
                    <button
                      type="button"
                      class="btn-close"
                      @click="showCreditModal = false"
                      :disabled="isButton"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="form-group mb-3">
                      <label>Şirket:</label>
                      <input
                        type="text"
                        class="form-control"
                        :value="values.company_name"
                        disabled
                      />
                    </div>
                    <div class="form-group">
                      <label for="credit">Yüklenecek Kontor Miktarı:</label>
                      <input
                        v-model.number="newCredit.credit"
                        type="number"
                        class="form-control"
                        id="credit"
                        min="1"
                        required
                      />
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="showCreditModal = false"
                      :disabled="isButton"
                    >
                      İptal
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="addCredit"
                      :disabled="isButton"
                    >
                      Onayla
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-backdrop fade show" v-if="showCreditModal"></div>

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="dataTable"
                :items="getData"
                :fields="fields"
                :per-page="10"
                :total-rows="totalRows"
                responsive="sm"
                :current-page="currentPage"
                :busy.sync="isBusy"
              >
                <template #table-busy>
                  <div
                    class="d-flex flex-column align-items-center justify-content-center gap-1 my-2"
                  >
                    <Icon
                      icon="lucide:loader"
                      class="spinner-rotate"
                      :style="{ fontSize: '72px' }"
                    />
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      @change="onPageChange"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div
                class="col-12 d-flex justify-content-between align-items-center mb-3"
              >
                <h4 class="card-title m-0">Ödeme Bildirimi Listesi</h4>
                <button
                  @click="openNotificationModal"
                  class="btn btn-primary"
                  :disabled="isButton"
                >
                  <Icon icon="lucide:plus-circle" class="mr-1" /> Ekle
                </button>
              </div>
            </div>

            <!-- Ödeme Bildirimi Modal -->
            <div
              class="modal"
              tabindex="-1"
              :class="{ 'd-block': showNotificationModal }"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Ödeme Bildirimi Ekleme</h5>
                    <button
                      type="button"
                      class="btn-close"
                      @click="showNotificationModal = false"
                      :disabled="isButton"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="form-group mb-3">
                      <label>Şirket:</label>
                      <input
                        type="text"
                        class="form-control"
                        :value="values.company_name"
                        disabled
                      />
                    </div>
                    <div class="form-group">
                      <label for="total">Total:</label>
                      <input
                        v-model.number="newNotification.total"
                        type="number"
                        class="form-control"
                        id="total"
                        min="1"
                        required
                      />
                    </div>
                    <div class="mb-3">
                      <label for="formFile" class="form-label">Dosya Yükle</label>
                      <input 
                        class="form-control" 
                        type="file" 
                        id="formFile" 
                        @change="handleFileUpload"
                        ref="fileInput"
                        :disabled="isUploading"
                      />
                      <div v-if="isUploading" class="mt-2">
                        <small class="text-muted">
                          <i class="fas fa-spinner fa-spin me-1"></i>
                          Dosya yükleniyor...
                        </small>
                      </div>
                      <div v-if="newNotification.file_url" class="mt-2">
                        <small class="text-success">
                          <i class="fas fa-check me-1"></i>
                          Dosya yüklendi
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="showNotificationModal = false"
                      :disabled="isButton"
                    >
                      İptal
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="addNotification"
                      :disabled="isButton"
                    >
                      Onayla
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-backdrop fade show"
              v-if="showNotificationModal"
            ></div>

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="notificationTable"
                :items="getDataNotification"
                :fields="fieldsNotification"
                :per-page="10"
                :total-rows="totalRowsNotification"
                responsive="sm"
                :current-page="currentPageNotification"
                :busy.sync="isBusy"
              >
                <!-- Dosya kolonu için özel template -->
                <template #cell(file_url)="data">
                  <a 
                    v-if="data.value"
                    :href="`https://api.reservationtracksystem.com/${data.value}`" 
                    target="_blank"
                    class="btn btn-link p-0"
                  >
                    <Icon icon="lucide:file" />
                  </a>
                </template>

                <template #table-busy>
                  <div class="d-flex flex-column align-items-center justify-content-center gap-1 my-2">
                    <Icon icon="lucide:loader" class="spinner-rotate" :style="{ fontSize: '72px' }" />
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPageNotification"
                      :total-rows="totalRowsNotification"
                      :per-page="perPageNotification"
                      @change="onPageChangeNotification"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

/* v-select stillerini özelleştirme */
.v-select {
  background: #fff;
}

.v-select .vs__dropdown-toggle {
  border: none;
  height: 100%;
  padding: 0;
}

.v-select .vs__selected {
  margin: 0;
  padding: 0;
}

.v-select .vs__search {
  margin: 0;
}

.v-select .vs__actions {
  padding: 0 8px;
}
</style>
