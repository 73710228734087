<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import Multiselect from "vue-multiselect";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Cari Yönetimi | Güncelleme",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Cari Güncelleme",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Cari Listesi",
          href: "/current-account",
        },
        {
          text: "Cari Güncelleme",
          active: true,
        },
      ],
      isAlertVisibleSuccess: false,
      isAlertVisibleDanger: false,
      values: [],
      type: 0,
      account_holder: "",
      currency: [],
      totalRows: 1,
      currentPage: 1,
      fields: [
        { key: "id", sortable: true, label: "İşlem Numarası" },
        { key: "date", sortable: true, label: "İşlem Tarihi" },
        { key: "type", sortable: true, label: "İşlem Türü" }, // currency
        { key: "comment", sortable: true, label: "İşlem Açıklaması" },
        { key: "total", sortable: true, label: "İşlem Tutarı" },
      ],
      historyType: [
        { id: 1, text: "Giriş" },
        { id: 2, text: "Çıkış" }
      ],
      history: {
        date: "",
        type: "",
        comment: "",
        balance: ""
      },
      value_items: [],
      types: [
        { value: 1, name: "Personel" },
        { value: 2, name: "Acenta" },
        { value: 3, name: "Otel" },
        { value: 4, name: "Müşteri" },
      ],
      reservation: [],
      currencies: [],
      formData: {
        type: 0,
        balance: 0,
        comment: "",
        currency: "",
        date: "",
      },
      history_detail: []
    };
  },
  mounted() {
    this.getData(), this.getReservation(), this.getCurrency(), this.getHistoryDetail();
  },
  watch: {
    type(value) {
      if (value.name == "Personel") {
        this.getDatas("/employee/getAll", "value_items");
      }
      if (value.name == "Acenta") {
        this.getDatas("/agency/getAll", "value_items");
      }
      if (value.name == "Otel") {
        this.getDatas("/hotel/getAll", "value_items");
      }
      if (value.name == "Müşteri") {
        this.getDatas("/customer/getAll", "value_items");
      }
    }
  },
  methods: {
    getDatas(endpoint, dataKey, callback = null) {
      axios
        .get(process.env.VUE_APP_BASEURL + endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this[dataKey] = response.data?.data;
          if (callback) {
            callback(response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getData() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/current-account/${itemId}`;

      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.values = JSON.parse(response.data?.data.values);
          this.type = response.data?.data.type;
          this.account_holder = response.data?.data.account_holder;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getWhere(ctx, callback) {
      const params = "?page=" + ctx.currentPage;
      const itemId = this.$route.params.id;
      axios
        .get(process.env.VUE_APP_BASEURL + "/current-account/history/" + itemId + params, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const items = response.data.data;
            this.totalRows = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            callback(items);
          }
        })
        .catch((error) => {
          callback([]);
          console.error("Veri yüklenirken bir hata oluştu:", error);
        });
      this.isBusy = false;
      return;
    },
    getReservation() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + "/current-account/reservation/" + itemId;

      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.reservation = response.data?.totals;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCurrency() {
      const api_url = process.env.VUE_APP_BASEURL + "/exchange/getAll";

      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.currencies = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    update() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/current-account/${itemId}`;
      const data = {
        values: JSON.stringify(this.values),
        type: this.type.value,
        account_holder: this.account_holder,
      }
      axios
        .put(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
            this.$bvToast.toast('Başarıyla güncellendi.', {
            title: 'Başarılı',
            variant: 'success',
            solid: true
          });
        })
        .catch(() => {
            this.$bvToast.toast('Güncellenirken hata oluştu.', {
            title: 'Hata',
            variant: 'danger',
            solid: true
          });
        });
    },
    onPageChange(newPage) {
      this.currentPage = newPage;
    },
    submitModal(){
        const itemId = this.$route.params.id;
        const api_url = process.env.VUE_APP_BASEURL + '/current-account/history';
        const data = {
          ...this.formData,
          relatedId: itemId
        };
        axios
          .post(api_url, data, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          })
          .then(() => {
            this.$bvToast.toast('Başarıyla eklendi.', {
                title: 'Başarılı',
                variant: 'success',
                solid: true
            });
            this.$bvModal.hide('bv-modal-example');
            this.formData = {
              type: 0,
              date: '',
              balance: '',
              currency: '',
              comment: ''
            };
            this.$refs.table.refresh();
          })
          .catch(() => {
            this.$bvToast.toast('Eklenirken hata oluştu.', {
                title: 'Hata',
                variant: 'danger',
                solid: true
            });
          });
    },
    getHistoryDetail(){
        const itemId = this.$route.params.id;
        const api_url = process.env.VUE_APP_BASEURL + `/current-account/history-detail/${itemId}`;
        axios
          .get(api_url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            this.history_detail = response.data?.data;
          })
          .catch(() => {
            this.$bvToast.toast("Getirilirken hata oluştu.", {
              title: "Hata",
              variant: "danger",
              solid: true,
            });
          });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="update">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="form-group mb-3">
                    <label for="bank">Hesap Adı</label>
                    <input v-model="account_holder" type="text" class="form-control" id="bank" />
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group mb-3">
                    <label for="acccount_holder">Hesap Türü</label>
                    <multiselect v-model="type" :options="types" label="name" :hover="false">
                    </multiselect>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group mb-3">
                    <label for="type">Cari</label>
                    <multiselect v-model="values" :options="value_items" label="name" :hover="false">
                    </multiselect>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="d-flex justify-content-end align-items-center">
                    <button type="submit" class="btn btn-primary px-4">Kaydet</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      
      <div class="col-4 col-md-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h4 class="card-title">Rezervasyon Açık Bakiye</h4>
                
                <div v-if="reservation.tour">
                  <h5 class="mt-3">Tur Rezervasyonları</h5>
                  <div v-for="(value, key) in reservation.tour" :key="key">
                    <div class="d-flex justify-content-between">
                      <span>Tur {{currencies.find(c => c.id == key)?.symbol}}</span>
                      <span>{{Number(value).toLocaleString()}} {{currencies.find(c => c.id == key)?.symbol}}</span>
                    </div>
                  </div>
                </div>

                <div v-if="reservation.hotel">
                  <h5 class="mt-3">Otel Rezervasyonları</h5>
                  <div v-for="(hotel, index) in reservation.hotel" :key="index">
                    <div class="d-flex justify-content-between">
                      <span>Otel {{currencies.find(c => c.id == index)?.symbol}}</span>
                      <span>{{Number(hotel).toLocaleString()}} {{currencies.find(c => c.id == index)?.symbol}}</span>
                    </div>
                  </div>
                </div>

                <div v-if="reservation.fly">
                  <h5 class="mt-3">Uçuş Rezervasyonları</h5>
                  <div v-for="(fly, index) in reservation.fly" :key="index">
                    <div class="d-flex justify-content-between">
                      <span>Uçuş {{currencies.find(c => c.id == index)?.symbol}}</span>
                      <span>{{Number(fly).toLocaleString()}} {{currencies.find(c => c.id == index)?.symbol}}</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h4 class="card-title">Bakiye</h4>
                
                <div v-for="(value, key) in history_detail" :key="key" class="w-100">
                  <div class="d-flex justify-content-between w-100">
                    <div v-for="(val, curr) in value" :key="curr" class="w-100">
                      <li class="list-group-item d-flex justify-content-between align-items-center w-100">
                        {{ key == 3 ? "Para Çekme:" : "Para Yatırma:" }}
                        <span> {{Number(val).toLocaleString()}} {{currencies.find(c => c.id == curr)?.symbol}}</span>
                      </li>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-8">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-12 d-flex justify-content-between align-items-center mb-3">
                <h4 class="card-title m-0">İşlem Kayıtları</h4>
                <button @click="$bvModal.show('bv-modal-example')" class="btn btn-primary">İşlem Ekle</button>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-12">
                <div class="table-responsive mb-0">
                  <b-table :items="getWhere" :fields="fields" responsive="sm" :current-page="currentPage" ref="table">
                    <template #cell(type)="data" >
                      <b-badge :variant="data.item.type.variant">
                        {{ data.item.type.text }}
                      </b-badge>
                    </template>
                    <template v-slot:cell(balanceBelongsCurrency)="data">
                      {{ data.item.balance + " " + data.item.currencySymbol }}
                    </template>
                  </b-table>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" @change="onPageChange">
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <b-modal id="bv-modal-example" title="İşlem Ekle" hide-footer>
      <form @submit.prevent="submitModal">
        <div class="form-group">
          <label for="type">İşlem Tipi</label>
          <select v-model="formData.type" class="form-control" id="type">
            <option value="0" disabled>Seçiniz</option>
            <option value="3">Para Yatırma</option>
            <option value="4">Para Çekme</option>
          </select>
        </div>
        <div class="form-group">
          <label for="date">İşlem Tarih</label>
          <input type="date" v-model="formData.date" class="form-control" id="date" required>
        </div>
        <div class="form-group">
          <label for="balance">Miktar</label>
          <div class="d-flex">
            <input type="number" v-model="formData.balance" class="form-control w-75 mr-2" id="balance" required>
            <select v-model="formData.currency" class="form-control w-25">
              <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                {{ currency.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="comment">Açıklama</label>
          <textarea v-model="formData.comment" class="form-control" id="comment"></textarea>
        </div>
        <button type="submit" class="btn btn-primary float-right">Kaydet</button>
      </form>
    </b-modal>

    </div>

  </Layout>
</template>
