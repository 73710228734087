<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

Vue.use(CKEditor)

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Sistem Şirketleri Listesi",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, 
    ckeditor: CKEditor.component, vSelect},
  data() {
    return {
      title: "Sistem Şirketleri Ekle",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Sistem Şirketleri Listesi",
          href: "/systemcompany",
        },
        {
          text: "Sistem Şirketleri Ekle",
          active: true,
        },
      ],
      values: {
        company_name: "",
        authorized_id: "",
        company_detail: "",
      },  
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "indent",
          "outdent",
          "|",
          "blockQuote",
          "insertTable",
          "undo",
          "redo",
        ],
      },
      customers: [],
      showNewCustomerForm: false,
      newCustomer: {
        customer_name: "",
        customer_phone: "",
        customer_email: "",
        customer_passport_number: "",
        customer_nationality: "",
        isActive: 1,
      },
    };
  },
  async created() {
    await this.getCustomers()
  },
  methods: {
    onSubmit() {
      const api_url = process.env.VUE_APP_BASEURL + "/system-company";
      axios
        .post(api_url, this.values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$router.push("/systemcompany");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getCustomers() {
      const api_url = process.env.VUE_APP_BASEURL + "/customer/getAll";
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.customers = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async addNewCustomer() {
      try {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.token;
        const api_url = process.env.VUE_APP_BASEURL + "/customer";

        const response = await axios.post(api_url, this.newCustomer, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Yeni müşteriyi listeye ekle ve seç
        await this.getCustomers() // Listeyi yenile
        this.values.authorized_id = response.data.data.id // Yeni eklenen müşteriyi seç
        
        // Formu temizle ve gizle
        this.newCustomer = {
          customer_name: "",
          customer_phone: "",
          customer_email: "",
          customer_passport_number: "",
          customer_nationality: "",
          isActive: 1,
        }
        this.showNewCustomerForm = false
        
        this.$toast.success('Yetkili başarıyla eklendi')
      } catch (error) {
        console.error('Müşteri eklenirken hata:', error)
        this.$toast.error('Yetkili eklenirken bir hata oluştu')
      }
    },
    // Select için label ve value formatını belirleyen metod
    getCustomerLabel({ customer_name }) {
      return `${customer_name}`
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="company_name">Şirket Adı:</label>
                    <input
                      v-model="values.company_name"
                      type="text"
                      class="form-control"
                      id="company_name"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="authorized_id">Yetkili Kişi:</label>
                    <div class="d-flex gap-2">
                      <v-select
                        v-model="values.authorized_id"
                        :options="customers"
                        :get-option-label="getCustomerLabel"
                        :reduce="customer => customer.id"
                        class="flex-grow-1"
                        placeholder="Yetkili Kişi Seçiniz"
                        :searchable="true"
                        :filterable="true"
                      />
                      <button 
                        type="button" 
                        class="btn btn-primary mx-2" 
                        @click="showNewCustomerForm = !showNewCustomerForm"
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>

                  <!-- Yeni Müşteri Ekleme Formu -->
                  <div v-if="showNewCustomerForm" class="border col-12 p-3 rounded mt-2">
                    <h5>Yeni Yetkili Ekle</h5>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group mb-3">
                          <label for="customer_name">Müşteri Adı:</label>
                          <input
                            v-model="newCustomer.customer_name"
                            type="text"
                            class="form-control"
                            id="customer_name"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group mb-3">
                          <label for="customer_phone">Telefon Numarası:</label>
                          <input
                            v-model="newCustomer.customer_phone"
                            type="text"
                            class="form-control"
                            id="customer_phone"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group mb-3">
                          <label for="customer_email">Mail:</label>
                          <input
                            v-model="newCustomer.customer_email"
                            type="email"
                            class="form-control"
                            id="customer_email"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group mb-3">
                          <label for="customer_passport_number">Pasaport Numarası:</label>
                          <input
                            v-model="newCustomer.customer_passport_number"
                            type="text"
                            class="form-control"
                            id="customer_passport_number"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group mb-3">
                          <label for="customer_nationality">Uyruk:</label>
                          <input
                            v-model="newCustomer.customer_nationality"
                            type="text"
                            class="form-control"
                            id="customer_nationality"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end">
                      <button 
                        type="button" 
                        class="btn btn-primary"
                        @click="addNewCustomer"
                      >
                        Ekle
                      </button>
                    </div>
                  </div>
                <div class="col-12">
                  <div class="form-group mb-3">
                    <label for="company_detail">Şirket Detayı:</label>
                    <ckeditor
                      v-model="values.company_detail"
                      :editor="editor"
                      :config="editorConfig"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary px-4">Ekle</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
