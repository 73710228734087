export const statusEnum = [
  { value: 0, text: "Seçiniz" },
  { value: 1, text: "Rezervasyon Oluşturuldu." },
  { value: 2, text: "Rezervasyon Tamamlandı" },
  { value: 3, text: "Rezervasyon İptal Edildi" },
  { value: 4, text: "Ödeme Bekleniyor" },
];
export const payEnum = [
  { value: 0, text: "Seçiniz" },
  { value: 1, text: "Rezervasyon Oluşturuldu." },
  { value: 4, text: "Ödeme Bekleniyor" },
];

export const typeEnum = [
  { value: 0, text: "Seçiniz" },
  { value: 1, text: "Otel Rezervasyonu" },
  { value: 2, text: "Tur Rezervasyosnu" },
  { value: 3, text: "Uçak Rezervasyonu" },
];

export const bankTypeEnum = [
  { value: 0, text: "Seçiniz" },
  { value: 1, text: "Banka Hesabı" },
  { value: 2, text: "Kredi Kartı" },
  { value: 3, text: "Kasa" },
];

export const CariTypeEnum = [
  { value: 0, text: "Seçiniz" },
  { value: 1, text: "Otel Hesabı" },
  { value: 2, text: "Acenta Hesabı" },
];

export const roomTypeEnum = [
  { value: 0, text: "Seçiniz" },
  { value: 1, text: "Otel Odası" },
  { value: 2, text: "Rezidans" }
]

export const flyPacket = [
  { value:0, text: "Seçiniz"},
  { value:1, text: "Ekonomi"},
  { value:2, text: "Business"},
  { value:3, text: "Premium Business"},
  { value:4, text: "First"},
]

export const attachmentTypeEnum = [
  { value: 1, text: "Ödeme Bildirimi" },
]