export const menuItems = [
  {
    id: 1,
    label: "menuitems.dashboard.text",
    icon: "lucide:layout-dashboard",
    link: "/",
  },
  {
    id: 2,
    label: "menuitems.reservation.text",
    icon: "lucide:calendar-check",
    link: "/reservation",
  },
  {
    id: 3,
    label: "menuitems.hotel.text",
    icon: "lucide:hotel",
    subItems: [
      {
        id: 21,
        label: "menuitems.hotel.text",
        link: "/hotel",
      },
      {
        id: 22,
        label: "menuitems.room.text",
        link: "/room",
      },
    ],
  },
  {
    id: 4,
    label: "menuitems.crm.text",
    icon: "lucide:square-user-round",
    subItems: [
      {
        id: 21,
        label: "menuitems.crm.list.customer",
        link: "/customer",
      },
      {
        id: 22,
        label: "menuitems.crm.list.agency",
        link: "/agency",
      },
    ],
  },
  {
    id: 5,
    label: "menuitems.vehicle.text",
    icon: "lucide:car",
    link: "/vehicle",
    permission: 1,
  },
  {
    id: 6,
    label: "menuitems.accounting.text",
    icon: "lucide:calculator",
    permission: 3,
    subItems: [
      {
        id: 61,
        label: "menuitems.accounting.employee",
        link: "/employee",
      },
      {
        id: 62,
        label: "menuitems.accounting.expense",
        link: "/expense",
      },
      {
        id: 63,
        label: "menuitems.accounting.revenue",
        link: "/revenue",
      },
      {
        id: 64,
        label: "menuitems.accounting.case",
        link: "/banks",
      },
      {
        id: 65,
        label: "menuitems.accounting.current_account",
        link: "/current-account",
      },
      {
        id: 66,
        label: "menuitems.accounting.payments",
        link: "/accounting/payments",
      },
      {
        id: 67,
        label: "menuitems.accounting.type",
        link: "/incomeexpensetype",
      },
      {
        id: 68,
        label: "menuitems.accounting.exchange",
        link: "/exchange",
      },
      {
        id: 69,
        label: "menuitems.accounting.reports",
        link: "/reports",
      },
    ],
  },
  {
    id: 7,
    label: "menuitems.city.text",
    icon: "lucide:map-pinned",
    link: "/city",
  },
  {
    id: 8,
    label: "menuitems.user.text",
    icon: "lucide:user",
    link: "/users",
  },
  {
    id: 9,
    label: "menuitems.systemcompany.text",
    icon: "lucide:building",
    link: "/systemcompany",
  },
];
